/* src/components/LogoAnimation.css */

.logo-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full screen height */
    background-color: white;
  }
  
  .logo {
    width: 300px; /* Adjust as per your logo's size */
    animation: spin 2s ease-in-out, enlarge 1s forwards;
    animation-delay: 0s, 2s;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes enlarge {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(2); /* Adjust the scale as needed */
      opacity: 0;
    }
  }
  