@tailwind base;
@tailwind components;
@tailwind utilities;

/* App.css */
:root {
  --contrast-color-duo: #bc7490;

  --clr-primary-500: #70c1a1;
  --clr-primary-600: #5a9d89;

  --fs-300: 0.75rem; /*12px*/
  --fs-400: 0.875rem; /*14px*/
  --fs-500: 1rem; /*16px*/
  --fs-600: 1.125rem; /*18px*/
  --fs-700: 1.25rem; /*20px*/
  --fs-800: ;
  --fs-900: 1.5rem; /*24px*/

  --ff-header: ;
  --ff-main: ;
  --ff-footer: ;
}

input[type="password"]::-ms-reveal {
  display: none;
}

/* For Chrome, Edge (Chromium-based)
input[type="password"]::-webkit-credentials-auto-fill-button {
  display: none;
} */

textarea {
  font-family: inherit;
}

.title {
  color: var(--clr-primary-500);
  margin-bottom: 20px;
  margin-top: 0px;
}

.description {
  font-size: var(--fs-600);
  color: #555;
}

.main-content {
  margin-top: 100px;
  background-color: #f8f8f8;
  padding-bottom: 1px;
}

/* Navigation */

.navigation {
  background-color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 1000;
  box-shadow: none;
  transition: box-shadow 0.3s;
}

.navigation.scrolled {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.nav-container {
  background-color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 1000;
  box-shadow: none;
  transition: box-shadow 0.3s;
}

/* .nav-container.ismobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
} */

.nav-mobile-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  height: 80px;
}

.nav-logo {
  text-align: center;
  width: 100px;
  vertical-align: middle;
}

.nav-logo-mobile {
  height: 70px;
  width: auto;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: clamp(10px, 2vw, 100px);
}

.nav-link {
  color: var(--clr-primary-500);
  text-decoration: none;
  transition: color 0.3s;
  width: 133.8px;
  white-space: nowrap;
}

.nav-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-link:hover {
  color: #4b887a;
}

.navigation > * {
  flex-grow: 0;
}

/* Ham menu */
.ham-menu {
  height: 40px;
  width: 35px;
  position: fixed;
  cursor: pointer;
  left: 35px;
}

.ham-menu span {
  display: block;
  height: 4px;
  width: 100%;
  background-color: var(--clr-primary-500);
  border-radius: 25px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  transition: 0.3s ease;
}

.ham-menu span:nth-child(1) {
  top: 8px;
}

.ham-menu span:nth-child(2) {
  top: 18px;
}

.ham-menu span:nth-child(3) {
  top: 28px;
}

.ham-menu.active span:nth-child(1) {
  transform: rotate(45deg);
  top: 18px;
}

.ham-menu.active span:nth-child(2) {
  opacity: 0;
}

.ham-menu.active span:nth-child(3) {
  transform: rotate(-45deg);
  top: 18px;
}

/* Mobile-specific styles */

@media (max-width: 1050px) {
  .main-content {
    margin-top: 80px;
  }

  .navigation {
    justify-content: center;
  }

  .nav-links {
    display: none;
  }

  .nav-link {
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: var(--fs-700);
    width: auto;
  }

  .nav-links.mobile {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 999;
    padding-bottom: clamp(10px, 2vw, 100px);;
  }

  .nav-links.mobile.open {
    display: flex;
  }

  body.menu-open {
    overflow: hidden;
  }
  
}

/* Footer */

.footer {
  background-color: white;
  text-align: center;
  padding: 20px;
  font-size: var(--fs-400);
}

.social-links {
  margin-bottom: 10px;
}

.social-links a {
  margin: 0 10px;
}

.social-links img {
  width: 24px;
  height: auto;
}

.additional-links {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.additional-links a,
.additional-links Link {
  width: fit-content;
  text-decoration: none;
  color: #333;
  font-weight: 500;
  margin: 2px 0;
}

.additional-links a:hover,
.additional-links Link:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer {
    font-size: var(--fs-300);
  }

  .social-links img {
    width: 20px;
  }
}


/* Chat page */

.send-button {
  background-color: var(--clr-primary-500);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin: 10px 0;
  font-size: var(--fs-500);
}

.send-button:hover {
  background-color: var(--clr-primary-600);
}

.textarea {
  height: 150px
}

/* Profile Overview page */

.contact-message {
  padding-top: 20px;
}

.check_gif {
  height: 200px;
}

.show-on-mobile-only {
  display: none
}

.help-icon {
  width: 20px;
  height: 20px;
  /* margin-left: 5px; */
  filter: invert(77%) sepia(18%) saturate(731%) hue-rotate(105deg) brightness(89%) contrast(87%);
}

/* Modal overlay */
.modal-overlay {
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: flex-start; 
  padding-top: 150px; 
  z-index: 999;
}

/* Modal container */
.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.form-textarea {
  width: 200% !important;
  height: 100px;
}

.modal textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  box-sizing: border-box;
  resize: vertical;
}

/* Modal buttons */
.modal-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 1rem;
}

.modal-buttons .send-button,
.modal-buttons .cancel-button {
  padding: 10px 20px;
  font-size: var(--fs-500);
  cursor: pointer;
}

.cancel-leave-button {
  background-color: #f44336 ;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin: 10px 0;
  font-size: var(--fs-500);
}

.cancel-leave-button:hover {
  background-color: #d32f2f;
}

.bevestig-leave-button {
  background-color: var(--clr-primary-500);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin: 10px 0;
  font-size: var(--fs-500);
}

.bevestig-leave-button:hover {
  background-color: var(--clr-primary-600);
}

.cancel-button {
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin: 10px 0;
  margin-bottom: 20px;
  font-size: var(--fs-500);
  width: 120px;
}

.cancel-button:hover {
  background-color: #d32f2f !important;
}

/* svg class */

.size-5 {
  width: 30px; 
  height: 30px; 
  color: #717171;
}

.size-6 {
  width: 50px; 
  height: 50px; 
  color: var(--clr-primary-500);
}

/* Mobile versioning */

@media (max-width: 768px) {
  .send-button {
    width: 35vw;
    min-height: 50px;
  }
}